import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import ButtonGroup from "react-bulma-components/lib/components/button/components/button-group"
import Container from "react-bulma-components/lib/components/container/container"
import Content from "react-bulma-components/lib/components/content/content"

import Heading from "react-bulma-components/lib/components/heading/heading"
import Image from "react-bulma-components/lib/components/image/image"
import Columns from "react-bulma-components/lib/components/columns/columns"
import Column from "react-bulma-components/lib/components/columns/components/column"
import { ArrowRight } from "reusable-components/ui/buttons"
import { getImageUrl } from "takeshape-routing"
import Section from "../generic"

import Styles from "./index.module.scss"


const BlogInterferer = props => {
	const query = useStaticQuery(graphql`
		query {
			takeshape {
				getHome {
					externalArticle {
						description
						image {
							path
						}
						link
						title
					}
				}
			}
		}
	`)

	const {
		description,
		image,
		link,
		title
	} = query.takeshape.getHome.externalArticle

	return (
		<Section { ...props }>

			<Container>
				<div className={ Styles.container }>
					<Columns centered>
						<Column
							mobile={ {
								size: "full"
							} }
							tablet={{
								size: 6
							}}
						>
							<Image src={ getImageUrl(image.path) } />
						</Column>
					</Columns>

					<Heading
						textSize={ 3 }
						textAlignment={ "centered" }
						className={ "has-margin-top-md has-margin-bottom-lg" }
					>
						{ title }
					</Heading>

					<Content
						dangerouslySetInnerHTML={ { __html: description } }
						className={ Styles.description }
					/>

					<ButtonGroup position={ "centered" }>
						<ArrowRight
							color={ "primary" }
							renderAs={ "a" }
							href={ link }
							target={ "_blank" }
							rel={ "noopener noreferrer" }
							className={ Styles.button }
						>
							Zum Artikel
						</ArrowRight>
					</ButtonGroup>
				</div>
			</Container>
		</Section>
	)
}

export default BlogInterferer
