import { graphql, useStaticQuery } from "gatsby";
import GatsbyImage from "gatsby-image";
import React from "react";
import FadeIn from "react-fade-in";

import Hero from "react-bulma-components/lib/components/hero/hero";
import Heading from "react-bulma-components/lib/components/heading/heading";
import Container from "react-bulma-components/lib/components/container/container";
import Content from "react-bulma-components/lib/components/content/content";

import OpticalAlignment from "optical-aligned-text";
import { cn } from "reusable-components/helper";
import Tooltip from "reusable-components/ui/tooltip";

import NavElements from "../../ui/nav/nav-elements";

import Styles from "./teaser.module.scss";
import NecktieSrc from "../../../assets/sections/home/necktie.svg";


const Teaser = props => {
	const query = useStaticQuery(graphql`
        query {
            takeshape {
                getHome {
                    teaser {
                        headlineFirstPart
                        headlineSecondPart
                        dudenDefs {
                            headline
                            description
                        }
                    }
                }
            }
        }
	`);

	const {
		headlineFirstPart,
		headlineSecondPart,
		dudenDefs
	} = query.takeshape.getHome.teaser;

	return (
		<Hero
			{ ...props }
			className={ Styles.section }>

			<Container className={ Styles.defsContainer }>
				<img
					alt=""
					className={ Styles.necktie }
					src={ NecktieSrc }
				/>

				<FadeIn>
					<OpticalAlignment
						rules={ [ { test: /^\n?R/, offset: -0.12 } ] }
						affectedTags={ ["span"] }>

						<Heading
							className={ Styles.teaserHeadline }
							renderAs={ "h1" }>
							<span>{ headlineFirstPart }</span>
							<span>{ headlineSecondPart }</span>
						</Heading>

					</OpticalAlignment>

					<ul className={ Styles.defs }>

						{ dudenDefs.map(({ headline, description }) =>
							<li
								key={ headline }
								className={ Styles.def }>

								<Heading
									subtitle
									className={ Styles.tag }>
									{ headline }
								</Heading>

								<Content>
									{ description }
								</Content>

							</li>
						) }
					</ul>
				</FadeIn>

			</Container>

			<FadeIn delay={ 2000 }>
				<Tooltip
					onClick={ handleScrollDown }
					className={ Styles.tooltip }>
					<i className={ cn("icon-mouse", Styles.icon) } />
					Scrolle nach unten
				</Tooltip>
			</FadeIn>
		</Hero>
	);
};

const handleScrollDown = () => {
	const section = document.querySelector(`#${ NavElements[1].target }`);

	if (section) {
		section.scrollIntoView({ behavior: "smooth" })
	} else {
		console.error(`Couldn’t find scroll to element  #${ NavElements[1].target }`);
	}
}

export default Teaser;
