import React, { Component } from "react";
import { graphql } from "gatsby";

import { Page } from "reusable-components";
import BlogInterferer from "../components/sections/blog-interferer";
import Contact from "../components/sections/contact";
import ExternalArticle from "../components/sections/external-article"
import Footer from "../components/sections/footer";

import Teaser from "../components/sections/home/teaser";
import Services from "../components/sections/services";
import Author from "../components/ui/author";
import Nav from "../components/ui/nav";


export default class Home extends Component {
	static propTypes = {};

	static defaultProps = {};

	render () {
		const {
			getHome,
			getGeneralData,
		} = this.props.data.takeshape;

		const {
			siteUrl,
			author,
			language,
		} = this.props.data.site.siteMetadata;

		return (
			<Page
				title={ `${ getHome.title } | ${ getGeneralData.titleAppendix }` }
				description={ getGeneralData.description }
				absoluteUrl={ siteUrl }
				author={ author }
				absoluteTeaserImagePath={ siteUrl }
				language={ language }
				location={ {
					...getGeneralData.location,
					locationName: getGeneralData.companyName,
				} }>

				<Nav />

				<Teaser id={ "home" } />

				<ExternalArticle/>

				<Services id={ "services" } />

				<BlogInterferer />

				<Contact id={ "contact" } />

				<Footer />

				<Author />

			</Page>
		);
	}
}

export const query = graphql`
    query {
        site {
            siteMetadata {
                siteUrl
                language
                author
            }
        }

        takeshape {
            getHome {
                title
            }

            getGeneralData {
                titleAppendix
                companyName
                description
                location {
                    latitude
                    longitude
                }
            }
        }
    }
`;
