import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import Heading from "react-bulma-components/lib/components/heading/heading";
import Container from "react-bulma-components/lib/components/container/container";
import Content from "react-bulma-components/lib/components/content/content";
import ButtonGroup from "react-bulma-components/lib/components/button/components/button-group";

import { cn } from "reusable-components/helper";
import { ArrowRight } from "reusable-components/ui/buttons";

import ArticleTile from "../../ui/tiles/article-tile";
import Section from "../generic";

import Styles from "./index.module.scss";


const BlogInterferer = props => {
	const query = useStaticQuery(graphql`
        query {
            takeshape {
                getHome {
                    blogInterferer {
                        title
                        descriptionHtml
                    }
                }

                getBlogartikelList(sort: {field: "_updatedAt", order: "desc"}, size: 1) {
                    items {
                        id: _id
                    }
                }
            }
        }
	`);

	const {
		title,
		descriptionHtml,
	} = query.takeshape.getHome.blogInterferer;

	const latestArticleId = query.takeshape.getBlogartikelList.items[0].id;

	return (
		<Section { ...props }>

			<Container>
				<div className={ Styles.container }>
					<i className={ cn("icon-blog", Styles.icon) }/>

					<Heading
						textSize={ 3 }
						textAlignment={ "centered" }
						className={ "has-margin-top-md has-margin-bottom-lg" }>
						{ title }
					</Heading>

					<Content
						dangerouslySetInnerHTML={ { __html: descriptionHtml } }
						className={ Styles.description }
					/>

					<ArticleTile
						articleId={ latestArticleId }
						isBrandNew
						className={ Styles.articleTile }
					/>

					<ButtonGroup position={ "centered" }>
						<ArrowRight
							color={ "primary" }
							renderAs={ "a" }
							href={ "/blog/" }
							rel={ "noopener noreferrer" }
							className={ Styles.button }>
							Zum Blog
						</ArrowRight>
					</ButtonGroup>
				</div>
			</Container>
		</Section>
	);
};

export default BlogInterferer;
