import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import Heading from "react-bulma-components/lib/components/heading/heading";
import Content from "react-bulma-components/lib/components/content/content";
import Container from "react-bulma-components/lib/components/container/container";
import Columns from "react-bulma-components/lib/components/columns/columns";
import Column from "react-bulma-components/lib/components/columns/components/column";
import ButtonGroup from "react-bulma-components/lib/components/button/components/button-group";
import DefaultButton from "reusable-components/ui/buttons";
import { cn } from "reusable-components/helper";
import { scrollToContact } from "../../../helper";

import Section from "../generic";
import Styles from "./index.module.scss";


const Services = (props) => {
	const query = useStaticQuery(graphql`
        query {
            takeshape {
                getHome {
                    services {
                        title

                        legalFields {
                            legalField {
                                name
                                icon
                                descriptionHtml
                            }
                        }

                        contactBtnTitle
                    }
                }
            }
        }
	`);

	const {
		title,
		legalFields,
		contactBtnTitle,
	} = query.takeshape.getHome.services;

	return (
		<Section
			isDark
			hasSeparatorTop
			hasSeparatorBottom
			{ ...props }>

			<Heading
				size={ 3 }
				textAlignment={ "centered" }
				renderAs={ "h2" }
				className={ "has-margin-bottom-xl" }>
				{ title }
			</Heading>

			<Container>
				<Columns
					centered
					className={ Styles.tilesContainer }>

					{ legalFields.map(({ legalField }) => renderTile(legalField)) }

				</Columns>
			</Container>

			<ButtonGroup position={ "centered" }>
				<DefaultButton
					onClick={ scrollToContact }
					onKeyDown={ scrollToContact }
					color={ "primary" }>
					{ contactBtnTitle }
				</DefaultButton>
			</ButtonGroup>

		</Section>
	);
};

const renderTile = ({ name, icon, descriptionHtml }) =>
	<Column
		key={ name }
		mobile={ { size: 12 } }
		tablet={ { size: "half" } }
		desktop={ { size: 5 } }>

		<div className={ Styles.tile }>
			<div className={ Styles.iconWrapper }>
				<i className={ cn(Styles.icon, `icon-${ icon }`) } />
			</div>

			<Heading
				textAlignment={ "centered" }
				textColor={ "primary" }
				textWeight={ "bold" }
				textSize={ 5 }
				subtitle>
				{ name }
			</Heading>

			<Content
				className={ Styles.tileContent }
				dangerouslySetInnerHTML={ { __html: descriptionHtml } }
			/>
		</div>

	</Column>;

export default Services;
